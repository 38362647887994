import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/stuDisciplinaryManage',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/stuDisciplinaryManage/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/stuDisciplinaryManage',
    method: 'put',
    data
  })
}


export function getDtoById(id) {
  return request({
    url: 'api/stuDisciplinaryManage/' + id,
    method: 'get'
  })
}

export function uploadFile(data) {
  return request({
    url: 'api/stuDisciplinaryManage/uploadFile',
    method: 'post',
    data
  })
}
export function stuCancel(data) {
  return request({
    url: 'api/stuDisciplinaryManage/stu/cancel',
    method: 'post',
    data
  })
}

export default { add, edit, del,getDtoById,uploadFile ,stuCancel}
