<template>
  <div>
    <van-form ref="form">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" readonly label="姓名" />
        <van-field v-model="form.stuNo" readonly label="学号" />
        <van-field v-model="form.gradeNo" readonly label="年级" />
        <van-field v-model="form.unitName" readonly label="院系" />
        <van-field v-model="form.specName" readonly label="专业" />
        <van-field v-model="form.className" readonly label="班级" />
      </van-cell-group>
      <!-- 基本信息 -->
      <van-cell-group title="违纪撤销申请">
        <van-field v-model="form.cancelRemark" rows="5" required :rules="[{ required: true }]" class="textarea-style" arrow-direction="" placeholder="请填写违纪撤销申请理由" label="申请理由" type="textarea" maxlength="300" />
      </van-cell-group>
    </van-form>
    <div class="submit-button shadow-style">
      <van-button round block type="primary" :loading="loading" native-type="submit" @click="doSubmit">提交</van-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getDtoById } from '@/api/modules/daily/disciplinary'
import { codeConvertLabel } from "@/utils";
import crudStuDisciplinaryManage from '@/api/modules/daily/disciplinary'

export default {
  name: "DisciplinaryDeatil",
  dicts: ["disciplinary_punish_res", "disciplinary_punish_date", 'disciplinary_punish_behavior'],
  data() {
    return {
      form: {
        id: null,
        description: null,
        file: null,
        filename: null,
        unitName: null,
        specName: null,
        gradeNo: null,
        className: null,
        cardNo: null,
      },
      loading: false,
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    this.$nextTick((e) => {
      getDtoById(this.$route.query.id).then((res) => {
        this.form = { ...res }
      })
    })
  },
  methods: {
    codeConvertLabel,
    onClickLeft() {
      this.form = {}
      this.loading = false
      this.$router.go(-1)
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          // 验证通过数据提交
          this.$dialog
            .confirm({
              title: "提示",
              message: "你确定要进行提交操作吗？",
            })
            .then(() => {
              this.loading = true;
              let cancelForm = {
                id: this.form.id,
                cancelRemark: this.form.cancelRemark,
              }
              crudStuDisciplinaryManage.stuCancel(cancelForm)
                .then((res) => {
                  this.$notify({
                    type: "success",
                    message: "提交成功",
                    duration: 2000,
                  });
                  this.onClickLeft();
                })
                .catch((e) => {
                  this.loading = false;
                });
            })
            .catch(() => {
              // on cancel
            });
        },
        (err) => {
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep.radio-other-info {
  .van-cell {
    .van-field__label {
      width: 11.2em;
    }
  }
}

.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.textarea-style {
  height: 100%;
}
</style>